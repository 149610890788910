.dropdown_search {
  padding: 0px 16px 6px;
}

.dropdown_search #dropdown_search {
  width: 100%;
  background-color: transparent;
  border: 1px solid #8d94be;
  border-radius: 5px;
  color: white;
  padding: 5px 8px;
  outline: none;
}

.dropdown_search_menu_item {
  align-items: start !important;
  gap: 10px;
}
.dropdown_search_menu_item .card_img img {
  max-width: 70px;
  max-height: 55px;
  object-fit: contain;
  min-width: 70px;
}
.dropdown_search_menu_item .drpdwn_car_details .car_name {
  font-size: 18px;
  /* margin-top: 5px; */
}
.dropdown_search_menu_item .drpdwn_car_details .price_tag {
  color: #4fc8e9;
  font-size: 12px;
  /* margin-top: 5px; */
}
.dropdown_label {
  margin-top: 3px;
}
.carDropdown {
  height: 60px !important;
}
.carDropdown .MuiSelect-select {
  display: flex !important;
  align-items: center !important;
  gap: 10px;
  padding-top: 13px !important;
}

.MuiSelect-select .card_img img {
  /* display: none; */
  max-width: 70px;
  border-radius: 3px;
  object-fit: contain;
  max-height: 55px;
}
.MuiSelect-select .drpdwn_car_details .car_name {
  color: white;
}
.MuiSelect-select .drpdwn_car_details .price_tag {
  /* display: none; */
  font-size: 12px;
}

.car_dropdown_paper {
  /* width: 330px !important; */
  /* min-width: auto !important; */
  /* right: 0rem !important; */
  /* left: auto !important; */
  max-height: 290px !important;
}
.car_full_dropdown_paper {
  /* width: 100% !important; */
}

.car_dropdown_root {
  /* width: 91%; */
}

@media (max-width: 426px) {
  .car_dropdown_root {
    width: 100%;
  }
  .car_dropdown.car_dropdown_paper_paper {
    width: 100% !important;
    left: 16px !important;
  }
}

.carCard_menuItem .unavailableMark {
  /* height: 270px; */
  position: absolute;
  width: 75px;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #413737c7;
  border-radius: 5px;
  font-size: 9px;
  color: #fff;
  text-transform: uppercase;
}