.trip_management_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
}

.trip_management .car_images {
  width: 210px;
}

.car_images .images {
  display: flex;
  align-items: center;
  gap: 10px;
}
.car_images img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.menu {
  width: 30px;
}

.trips_table {
  font-size: 14px;
}
.trips_table table{
  position: relative;
}

.trips_table .destination_trip {
  width: 150px;
}
.trips_table .destination_trip img {
  margin-right: 10px;
}

.trips_table tbody tr {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.trips_table tbody tr:hover {
  transform: scale(1.02);
}

.trips_table tbody .outboundCode {
  padding-right: 5px;
}

.trips_table tbody .returnCode {
  padding-left: 5px;
}

.tripDetailModalWrapper {
  align-items: center;
  overflow: hidden;
  z-index: 1000;
}

.tripDetailModal {
  width: 720px;
  height: 95vh;
  overflow-y: scroll;
  /* transform: translate(0, 5%); */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: thin; /* Firefox */
}
.tripDetailModal::-webkit-scrollbar {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.trip_modal_carousal {
  text-align: center;
}

.trip_modal_carousal img {
  max-width: 100%;
  border-radius: 20px;
  height: 230px;
  object-fit: cover;
}
.tripDetailModal .tripDetail {
  font-size: 14px;
  color: white;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  margin-top: 1rem;
}
.tripDetailModal .tripDetail_col {
  font-size: 14px;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 50%;
  margin: 0 3px;
}

.modal_detail_header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.tripDetailModal .req_reimbursement {
  color: #ff2552;
  font-weight: bold;
  cursor: pointer;
}

.tripDetailModal .tripDetail span {
  color: #8d94be;
}
.tripDetailModal .trip_detail_actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}
.tripDetailModal .trip_detail_actions .all_users_primary_button,
.tripDetailModal .trip_detail_actions .all_users_secondary_button {
  width: 50% !important;
  padding: 10px;
}

.reimbursementModal {
  width: 500px;
}

.reimbursementModal .confirmation_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.reimbursementModal .confirmation_btn button {
  color: white;
  border-radius: 10px;
}
.reimbursementModal .confirmation_btn button svg {
  margin-right: 10px;
}
.reimbursementModal .confirmation_btn button:first-child {
  background-color: #252b48;
}

.reimbursementModal .user_upload_btn {
  padding: 10px 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reimbursementModal .user_upload_btn div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.trips_table .photos {
  background-color: #1c2038 !important;
}

.trips_table .photos_button {
  background-color: #8d94be !important;
}

.trips_table .reimbursement_th {
  width: 10%;
}

.trip_management {
  justify-content: start;
  flex-direction: column;
}
.trip_management .editContainer {
  width: 100%;
}

.trip_management .editContainer .user_upload_btn {
  padding: 3rem 0 !important;
}

.date_trip_mngmnt {
  width: 100%;
  margin-top: 10px !important;
}

.date_trip_mngmnt .MuiInputLabel-root {
  top: 2px;
}

.editBookingModal .editModal_header {
  position: relative;
  justify-content: center;
}

.editBookingModal .go_back {
  position: absolute;
  left: 0;
}

/* .clientInfoNotAvailable svg {} */

.close_icon {
  /* background-color: rgba(0, 0, 0, 0.55); */
  border-radius: 100%;
  padding: 5px;
  position: absolute;
  z-index: 1000;
  cursor: pointer;
}
