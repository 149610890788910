.mobile_nav_bar {
  display: none !important;
  background: #252b48;
  padding: 0 3%;
  justify-content: space-between;
  align-items: center;
  color: white !important;
  box-shadow: 0px 2px 6px 2px #14182b;
  z-index: 100;
  position: fixed !important;
  width: 100%;
  margin: -1rem;
}
.logo img {
  max-width: 100px;
}

.nav_buttons {
  padding: 0 5%;
}
.nav_buttons a {
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  color: white;
}
.nav_buttons a:hover {
  opacity: 0.8;
}
.nav_buttons_search {
  background: #1c2038;
  height: 22px;
  border-radius: 5px;
  width: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
}

.mobile_nav_bar .navbar-toggler {
  border: none;
  box-shadow: none;
}
.navbar-toggler:focus {
  box-shadow: none !important;
}

.nav_search::before {
  border-color: 60647D !important;
  border: 1px solid #60647d !important;
  height: 100%;
  border-radius: 5px;
}
.nav_search {
  padding: 0 16px;
  width: 200px;
  color: white !important;
  font-size: 10px !important;
  margin-left: 2rem;
}
.nav_search_svg svg {
  width: 15px;
  cursor: pointer;
}
.nav_search input {
  height: 25px;
}
.nav_search::after {
  height: 100%;
  border: none !important;
  /* border: 2px solid white !important; */
}
@media (max-width: 992px) {
  .nav_buttons {
    align-items: flex-start;
    padding: 0 5% 1rem !important;
  }
}

@media (max-width: 426px) {
  .mobile_nav_bar {
    display: flex !important;
    padding: 0 0% !important;
  }
  .nav_buttons_search {
    margin-bottom: 1rem;
  }
  .nav_search {
    margin-left: 12px;
    margin-bottom: 16px;
  }
}
