.pagewrapper {
  padding: 1rem 0;
}

.booking {
  background: #252b48;
  border-radius: 10px;
  padding: 5px 10px;
}
.booking_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}
.booking_header h4 {
  font-size: 16px;
}
.dailyOverviewCalenderWrapper
  .fc-header-toolbar
  .fc-toolbar-chunk:first-child
  div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dailyOverviewCalenderWrapper
  .fc-header-toolbar
  .fc-toolbar-chunk:first-child
  h2 {
  font-size: 16px;
}
.dailyOverviewCalenderWrapper
  .fc-header-toolbar
  .fc-toolbar-chunk:first-child
  button {
  background: transparent;
  border: none;
  font-size: 12px;
  color: #4fc8e9;
}
.dailyOverviewCalenderWrapper
  .fc-header-toolbar
  .fc-toolbar-chunk:first-child
  button:focus {
  box-shadow: none;
}

.dailyOverViewCalender .fc-scrollgrid {
  border-radius: 20px !important;
  overflow: hidden !important;
  border-color: #8d94be !important;
  border-right-width: thin !important;
  border-bottom-width: thin !important;
}
.dailyOverViewCalender .dayHeaderClassNames,
.dailyOverViewCalender .calender_blocks {
  border-color: #8d94be !important;
}
.fc-theme-standard td,
.fc-theme-standard th {
  border-color: #8d94be !important;
}
.fc-theme-standard:first-child td,
.fc-theme-standard:first-child th {
  border-left: none;
  border-top: none;
}
.dailyOverViewCalender .dayHeaderClassNames {
  border-top: none !important;
  background: #252b48 !important;
  border-color: #8d94be !important;
}
.dailyOverviewCalenderWrapper td,
.dailyOverviewCalenderWrapper th {
  border-right: none !important;
  border-bottom: 0 !important;
}
.dailyOverViewCalender .calender_blocks:first-child {
  border-left: none !important;
}

.calender_event_block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60px;
  width: 100%;
  cursor: pointer;
}
.monthView {
  height: 20px;
}
.monthView .monthViewTile {
  display: flex;
  align-items: center;
  gap: 10px;
  overflow: hidden;
}
.calender_blocks .calender_event {
  background-color: #252b48 !important;
  padding: 8px;
  border-radius: 10px;
  margin: 0 5px 10px !important;
  border: 2px solid #3788d8;
  box-shadow: none !important;
}
.calender_blocks .calender_event_out {
  border-color: #ff2552;
}

.calender_event .event_block_type {
  color: #4fc8e9;
  text-align: left;
  font-size: 10px;
}

.calender_event .event_block_status {
  color: white;
  text-align: center;
  font-size: 10px;
}

.calender_event .event_block_time {
  color: #4fc8e9;
  text-align: right;
  font-size: 10px;
}

.calender_event_out .event_block_type {
  color: #ff2552;
  text-align: left;
  font-size: 10px;
}

.calender_event_out .event_block_status {
  color: white;
  text-align: center;
  font-size: 10px;
}

.calender_event_out .event_block_time {
  color: #ff2552;
  text-align: right;
  font-size: 10px;
}

.dailyOverviewCalenderWrapper .fc-more-popover {
  background: #252b48 !important;
  border-radius: 5px !important;
  border: none !important;
}

.dailyOverviewCalenderWrapper .fc-more-popover .fc-popover-header {
  background: #1a1f3cb5;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.dailyOverviewCalenderWrapper .fc-timegrid-slot-minor {
  border-top-style: none !important;
}

.dailyOverviewCalenderWrapper .fc-timegrid-slot {
  height: 45px !important;
}

.dailyOverviewCalenderWrapper .fc-timegrid-more-link {
  background-color: #131437;
  color: yellow;
  box-shadow: none;
  border: 1px solid #4fc8e9;
}
