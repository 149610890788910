.car_report .chart_row .chart_container {
  width: 60%;
  display: flex;
  justify-content: space-between;
  background-color: #252b48;
  border-radius: 10px;
  padding: 1rem 1rem 0 0.5rem;
  gap: 1rem;
}
.car_report .chart_row .chart {
  padding: 0;
  width: 100%;
}
.car_report .chart_row .counters {
  width: 40%;
}
.car_report .chart_row .counters .counter {
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.car_report .chart_row .car_engine_detail {
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 1rem;
}
.car_report .chart_row .car_engine_detail .detail_card {
  border: 1px solid;
  border-radius: 12px;
  padding: 1rem;
  text-align: center;
  font-size: 14px;
}
.car_report .chart_row .car_engine_detail .detail_card .amount {
  font-weight: bold;
  font-size: 22px;
}
.car_report .chart_row .car_engine_detail .power {
  border-color: #ff2552;
}
.car_report .chart_row .car_engine_detail .power .amount {
  color: #ff2552;
}
.car_report .chart_row .car_engine_detail .torque {
  border-color: #ffb600;
}
.car_report .chart_row .car_engine_detail .torque .amount {
  color: #ffb600;
}
.car_report .chart_row .car_engine_detail .engine {
  border-color: #4fc8e9;
}
.car_report .chart_row .car_engine_detail .engine .amount {
  color: #4fc8e9;
}

.car_report .roi_calculator .chart_row .counters {
  width: 100%;
}
.car_report .roi_calculator .chart_row .counters .counter {
  background-color: #1c2038;
  height: 125px;
}

.car_report .dashboard_container .customer_report {
  height: auto;
}

.chart_row .counters .counter .total_car_saving svg {
  border: none;
  background-color: #252b48;
}
.chart_row .counters .counter .total_car_saving svg g rect {
  fill: #ff2552;
}

.car_report .roi_calculator .meters_counter {
  padding: 0 1rem 1rem;
  font-size: 12px;
  text-align: center;
}
.car_report .roi_calculator .meters {
  display: flex;
  justify-content: center;
  align-items: center;
}

.car_report .roi_calculator .meters svg {
  width: auto;
  height: auto;
  border: none;
  border-radius: inherit;
}

.car_report .car_counter svg {
  border: none !important;
  background-color: #1c2038;
}
.car_report .car_counter .icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.car_report .car_counter .car svg g rect {
  fill: #ff2552 !important;
}
.car_report .car_counter .rent svg g rect {
  fill: #4fc8e9 !important;
}
/* ********************* */

.car_report .carVinNo span {
  color: #4fc8e9;
}
.car_report .car_reports {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
}
.car_report .monthTable table {
  border-collapse: collapse !important;
}

.car_report .monthTable tbody,
.car_report .monthTable td,
.car_report .monthTable th {
  border: 1px solid #4f5054;
  padding: 4px 0;
  border-radius: 1px;
  text-align: center;
}
.car_report table .delivery {
  /* border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-left: 15px; */
}
.car_report table .fuel {
  /* border-top-right-radius: 10px;
  border-bottom-right-radius: 10px; */
}
.car_report table .charges {
  background-color: #bc8b117a;
}
.car_report table thead th {
  font-size: 13px;
}
.car_report .monthTable table thead th {
  font-size: 12px;
}
.car_report table tbody tr {
  font-size: 14px;
}
.car_report .reportTable {
  border-bottom: 1px solid;
  padding-bottom: 1rem;
}
.car_report .monthAnalysis {
  font-size: 14px;
  padding-top: 1rem;
}
.car_report .monthAnalysis .title {
  font-size: 15px;
  font-weight: 600;
}

.car_report .monthAnalysis .totals {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 0.5rem 0;
  color: #d1cfcf;
}
.car_report .quartrUtilization {
  font-size: 16px;
}
.car_report .quartrUtilization .totals {
  justify-content: flex-start;
}

.car_report .monthAnalysis .totals span {
  color: #4fc8e9;
}

.car_report .quarterAnalysis {
  width: 100%;
}
.car_report .quarterAnalysis .title {
  color: #4fc8e9;
  font-weight: bold;
}

.car_report .quarterAnalysisContainer {
  background: #252b48;
  margin-top: 1rem;
  border-radius: 12px;
  width: 100%;
  padding: 1rem;
  /* width: 100%; */
}

@media (max-width: 426px) {
  .car_report .chart_row .counters {
    width: 100%;
  }
  .car_report .chart_row .chart_container {
    width: 100%;
    flex-direction: column;
  }
  .car_report .chart_row .chart_container .car_engine_detail {
    width: 100%;
    margin-top: 1rem;
  }
  .car_report .chart_row .chart_container .car_engine_detail .detail_card {
    width: 100%;
    margin-bottom: 1rem;
  }
  .car_report .dashboard_row {
    flex-direction: column;
  }
  .car_report .roi_calculator .pending_requests {
    height: auto;
  }
}
