.pagewrapper {
  padding: 1rem 0;
}

.booking {
  background: #252b48;
  border-radius: 10px;
  padding: 5px 10px;
}
.booking_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}
.booking_header h4 {
  font-size: 16px;
}
.booking_header button {
  /* background-color: #ff2552; */
  width: 120px !important;
  padding: 5px;
  color: white;
  border-radius: 3px;
  border: 1px solid transparent;
  font-size: 12px;
  transition: all 0.2s ease-in-out;
  text-transform: none;
}

.allUsers_header_actions {
  display: flex;
  gap: 1rem;
}

.allUsers_header_actions button:first-child {
  background-color: #4fc8e9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
}

.allUsers_header_actions button:last-child {
  background-color: #ff2552;
}

.booking_header button:first-child:hover {
  background-color: transparent;
  border: 1px solid #4fc8e9;
}

.booking_header button:last-child:hover {
  background-color: transparent;
  border: 1px solid #ff2552;
}

.add_admin_dropdown .MuiPaper-root {
  width: 150px;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.29);
}

.add_admin_dropdown .MuiMenuItem-root {
  font-size: 14px;
}

.allUsers_table {
  background-color: #252b48;
  border-radius: 10px;
  padding: 5px 1rem 1rem;
}

table {
  width: 100%;
  border-collapse: separate !important;
  border-spacing: 0px 15px;
}
table thead {
  margin-bottom: 1rem;
}
table .profile img {
  border-radius: 100%;
  max-width: 48px;
}

table .table_actions {
  width: 160px;
}

.allUsers_table .table_actions {
  width: 250px;
}

table .table_actions button {
  width: 70px;
  padding: 5px;
  color: white;
  border-radius: 3px;
  /* border: 1px solid transparent; */
  font-size: 12px;
  transition: all 0.2s ease-in-out;
}

table .table_actions button:first-child {
  background-color: transparent;
  border: 1px solid #4fc8e9;
  margin-right: 1rem;
}

table .table_actions button:nth-child(2) {
  background-color: transparent;
  border: 1px solid #4fc8e9;
  margin-right: 1rem;
}

table .table_actions button:nth-child(2):hover {
  background-color: #4fc8e9;
}

table .table_actions button:last-child {
  background-color: transparent;
  border: 1px solid #ff2552;
}

table .table_actions button:first-child:hover {
  background-color: #4fc8e9;
}

table .table_actions button:last-child:hover {
  background-color: #ff2552;
}

table .table_actions button svg {
  width: 20px;
}

table tr {
  color: #bebebe;
}

table tr .role {
  color: #4fc8e9;
}

.carFeatures {
  margin: 1rem 0;

  background: #252b48;
  border-radius: 10px;
  padding: 1rem;
}
.carFeatures_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.feature_checkboxs {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap;
}
.feature_checkbox_wrapper {
  width: 16% !important;
  padding: 10px 10px 10px 15px;
}
.feature_checkbox_wrapper span {
  font-size: 13px !important;
  width: 100%;
}
.feature_checkbox {
  color: #4fc8e9 !important;
}
.feature_checkbox[aria-disabled='true'] {
  color: #3e7b8c !important;
}
.feature_checkbox_wrapper .MuiFormControlLabel-label.Mui-disabled {
  color: rgb(203, 197, 197) !important;
}
.features_actions {
  display: flex;
  justify-content: end;
  align-items: flex-end;
}
.all_users_primary_button {
  /* background-color: #ff2552; */
  width: 80px !important;
  padding: 5px;
  color: white;
  border-radius: 3px;
  border: 1px solid transparent;
  font-size: 12px;
  transition: all 0.2s ease-in-out;
  text-transform: none;
  background-color: #4fc8e9;
}

.all_users_primary_button:hover {
  background-color: transparent;
  border: 1px solid #4fc8e9;
}
.all_users_secondary_button {
  /* background-color: #ff2552; */
  width: 120px !important;
  padding: 5px;
  color: white;
  border-radius: 3px;
  border: 1px solid transparent;
  font-size: 12px;
  transition: all 0.2s ease-in-out;
  text-transform: none;
  background-color: #ff2552;
}

.all_users_secondary_button:hover {
  background-color: transparent;
  border: 1px solid #ff2552;
}

@media (max-width: 426px) {
  table {
    font-size: 9px;
    border-spacing: 0px 10px;
  }
  table .profile img {
    max-width: 28px;
  }
  table .table_actions {
    width: 55px;
    text-align: end;
  }
  table .table_actions button {
    width: 50px;
    padding: 2px;
    font-size: 8px;
    margin-bottom: 0.3rem;
    margin-right: 0 !important;
  }
  table .table_actions button svg {
    width: 10px;
  }
}

.margin-right10 {
  margin-right: 10px;
}

.carFeatures_edit_button {
  width: 120px !important;
  padding: 5px;
  color: white;
  border-radius: 3px;
  border: 1px solid transparent;
  font-size: 12px;
  transition: all 0.2s ease-in-out;
  text-transform: none;
  background-color: #4fc8e9;
}

.carFeatures_edit_button:hover {
  background-color: transparent;
  border: 1px solid #4fc8e9;
}

.carFeatures .cancel_button {
  background-color: #8d94be;
  width: 120px !important;
  padding: 5px;
  color: white;
  border-radius: 3px;
  border: 1px solid transparent;
  font-size: 12px;
  transition: all 0.2s ease-in-out;
  text-transform: none;
}

.carFeatures .cancel_button:hover {
  background-color: transparent;
  border: 1px solid #8d94be;
}

.feature_checkbox_label_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.feature_checkbox_del_icon {
  font-size: 10px;
  float: right;
  margin-bottom: 15px;
  margin-left: 10px;
  color: #ff2552;
  position: absolute;
  top: -20px;
  right: 0;
  /* margin-bottom: 12px; */
}

.create_feature_input {
  background-color: white;
  margin: 20px !important;
}
.add_new_airport {
  margin: 2rem 0;
}

.add_new_airport .btns {
  margin: 2rem 0;
}

.edit_user_modal {
  height: 450px !important;
}

.edit_user_modal svg {
  width: 30px;
}

.edit_user_modal .btns {
  margin-top: 10px;
}

.car_bookings_modal {
  width: 90% !important;
  height: 450px !important;
}

.car_bookings_modal_table {
  height: 390px !important;
}
