.trip_inormation .settings_body {
  gap: 1rem;
  background: #252b48;
  box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.trip_inormation .settings_body .uploadBtn {
  background-color: transparent;
  border: 1px solid #454d80;
  border-radius: 10px;
}

.trip_inormation .body_row {
  align-items: center;
  display: flex;
  gap: 2rem;
  justify-content: space-between;
}

.trip_inormation .info_row .MuiInputBase-root .Mui-disabled {
  -webkit-text-fill-color: #4fc8e9 !important;
}

.trip_inormation .info_row .red .MuiInputBase-root .Mui-disabled {
  -webkit-text-fill-color: red !important;
}

.settings_page .body_row .uploadBtn {
  width: 100%;
}

.before_trip,
.after_trip {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.reimbursement_link {
  text-decoration: underline !important;
  color: #4fc8e9 !important;
}

.reimbursement_link:hover {
  cursor: pointer;
}

.multiline textarea {
  overflow-y: scroll !important;
  height: 60px !important;
}

.info_row .multiline .MuiOutlinedInput-root {
  height: 80px !important;
}

.info_row .multiline .MuiOutlinedInput-notchedOutline {
  height: 80px !important;
}

.settings_body .user_upload_btn {
  padding: 1rem 0 !important;
  position: relative;
}
