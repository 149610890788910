.editWrapper {
  display: flex;
  justify-content: center;
}

.editContainer {
  background-color: #252b48;
  width: 70%;
  padding: 1rem;
  border-radius: 10px;
}

.editContainer .user_upload_btn {
  padding: 1rem 0 !important;
  position: relative;
}

.editContainer .additionalImages .additionalImgsBtns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  gap: 1rem;
}

.editContainer .additionalImages .user_upload_btn {
  width: 25% !important;
  padding: 1rem !important;
  height: 125px;
}
.editContainer .user_upload_btn img {
  /* width: 100px; */
  max-width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}
.editContainer .user_upload_btn .removeImageIcon {
  position: absolute;
  right: 6px;
  top: 6px;
}

.available {
  color: white !important;
}

.on_rent {
  color: white !important;
}

.out_of_service {
  color: white !important;
}

.repair {
  color: red !important;
}

.wash {
  color: white !important;
}

.refuel {
  color: white!important;
}
.detail {
  color: white !important;
}
.refuel_wash {
  color: white !important;
}
.rinse {
  color: white !important;
}

.employee {
  color: white !important;
}
.prepped {
  color: green !important;
}
.add_vehicle .carFeatures {
  padding: 1rem 0;
}
.add_vehicle .feature_checkbox_wrapper {
  width: 19% !important;
}

.info_row .field .dollor_sign {
  position: absolute;
  top: 11.1px;
  left: 14.4px;
  color: #9d9fa2;
}
.info_row .field input {
  padding-left: 26px;
}

@media (max-width: 426px) {
  .editContainer {
    width: 100%;
  }
  .editContainer .user_upload_btn {
    padding: 3rem 0 !important;
  }
}
