.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header .search {
  position: relative;
}
.header .search input {
  width: 300px;
  background-color: #252b48;
  box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  padding: 5px 30px 5px 10px;
  color: #f1f1f1;
  border: none;
  outline: none;
  font-size: 14px;
}
.header .search svg {
  position: absolute;
  right: 15px;
  top: 6px;
  width: 18px;
}
.header .search input::placeholder {
  color: #f1f1f1;
}

.header_btns {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.header_btn {
  background-color: #252b48;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.header_btn svg {
  width: 16px;
}

.profile_section {
  display: flex;
  gap: 10px;
  align-items: center;
}

.profile_section .profile_img img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 2px solid #4fc8e9;
  object-fit: cover;
}

.notification {
  position: relative;
}
.notification_popup_BG {
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
}
.notification_popup {
  top: 2.5rem;
  right: 0;
  position: absolute;
  width: 500px;
  max-height: 500px;
  background: #252b48;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.33);
  border-radius: 10px;
  padding: 10px;
  z-index: 100;
  /* overflow-y: scroll; */
}

.notification_subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #8d94be;
  font-size: 14px;
}

.notification_subheader span {
  cursor: pointer;
}
.notification_subheader span:last-child {
  color: #4fc8e9;
}

.notification_popup .content {
  display: flex;
}

.notification_popup .content_checkbox {
  display: flex;
}

.notification_popup .content_messages {
  word-break: break-word;
}

.notification_popup .day {
  font-size: 14px;
  margin: 10px 0;
}

.notification_popup .day_row {
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 12px;
  color: #8d94be;
  gap: 10px;
  padding: 7px 2px;
}

.notification_popup .day_row .read_checkbox {
  
}

.notification_popup .day_row .img img {
  max-width: 50px;
}

.notification_popup .day_row .body {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.notification_popup .day_row .body .content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.notification_popup .day_row .body .read_checkbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
}

.notification_popup .day_row .body .content .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #ffffff;
  width: 100%;
}
.notification_popup .day_row .body .content .title span:last-child {
  font-size: 12px;
  color: #4fc8e9;
}

/* .notification_popup .day_row .body .descp {

} */

@media (max-width: 426px) {
  .header {
    margin-top: 50px;
  }
  .header .search input {
    width: 210px;
  }
  .profile_section {
    display: none;
  }

  .notification_popup {
    right: -40px;
    width: 320px;
  }
}

.numberCircle {
  position: absolute;
  margin-left: 25px;
  margin-bottom: 25px;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  padding: 2px;

  background: #ff2552;
  border: 1px solid #ff2552;
  color: white;
  text-align: center;

  font: 17px Arial, sans-serif;
}
