.paymentDetails_wrapper {
  /* padding: 4rem 0; */
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.paymentDetails_wrapper .paymentDetails_card {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paymentDetails_wrapper .paymentDetails_card img {
  max-width: 300px;
  margin-top: 3rem;
}

.paymentDetails {
  width: 100%;
  z-index: 100;
}

.paymentDetails_wrapper .paymentPage {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  gap: 3rem;
}

.paymentDetails .paymentDetails_heading {
  font-size: 1.8rem;
  font-weight: bold;
}

.paymentDetails .card_details {
  margin: 2rem 10% 0 10%;
  padding: 1rem 2rem 2rem;
  background-color: #252b48;
  border-radius: 10px;
}

.card_details .card_number_header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1rem;
}

.card_label {
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 1;
}
.card_label span {
  font-size: 10px;
  color: #9d9fa2;
  font-weight: normal;
}

.card_number_header .card_edit {
  color: #4fc8e9;
  display: flex;
  gap: 5px;
  cursor: pointer;
  padding: 0.1rem 0.4rem;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  border: 1px solid transparent;
}
.editing {
  border-color: #4fc8e9 !important;
  /* text-decoration: underline; */
}
.card_number .sc-bczRLJ,
.card_number .fljFRU {
  width: 100%;
  margin: 1rem 0;
}

.card_number .sc-gsnTZi,
.card_number .eHZVqz,
.card_number .fpydRL,
.card_number .bVWHDv,
.card_number .drLbWb {
  background-color: transparent !important;
  border: 1px solid #454d80 !important;
  box-shadow: none !important;
}
.card_number .sc-gsnTZi input,
.card_number .eHZVqz input,
.card_number .fpydRL input,
.card_number .bVWHDv input,
.card_number .drLbWb input {
  background-color: transparent !important;
  color: white;
  width: 100%;
}
/* eHZVqz */

.cardRow {
  display: flex;
  margin-bottom: 1rem;
  color: white;
}

.cardRow_text {
  width: 50%;
}

.cvv_number_text .card_label {
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 1;
}
.cvv_number_text .card_label span {
  font-size: 10px;
  color: #9d9fa2;
  font-weight: normal;
}

.cardRow_input {
  width: 50%;
  position: relative;
}

.cardRow_input input {
  background: transparent;
  border: 1px solid #454d80;
  border-radius: 3px;
  width: 100%;
  outline: none;
  height: 100%;
  padding-left: 10px;
  color: white;
}
.cvv_number .cvv_number_input svg {
  position: absolute;
  right: 10px;
  top: 7px;
  cursor: pointer;
}
.cvv_visible path {
  stroke: #4fc8e9;
}

.expiry .expiry_input {
  /* width: 50%; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expiry .expiry_input input {
  background: transparent;
  border: 1px solid #454d80;
  border-radius: 3px;
  width: 45%;
  outline: none;
  height: 100%;
  padding-left: 10px;
  text-align: center;
}

.expiry .expiry_input .expiry_separator {
  height: 100%;
  width: 1px;
  transform: rotate(15deg);
  background: #9d9fa2;
}

.confirmation_btn button {
  background-color: #4fc8e9;
  border-radius: 3px;
  border: none;
  width: 100%;
  padding: 0.5rem;
}
.confirmation_btn button:hover {
  opacity: 0.9;
}

.MuiModal-root[aria-labelledby='conf_modal'] {
  display: flex;
  justify-content: center;
  align-items: center;
}

.confModal {
  width: 332px;
  height: 350px;
  /* background: #252b48; */
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08);
  border-radius: 22px;
  padding: 1rem;
  margin: 1rem;
}
.conf_hero_img img {
  max-width: 100%;
}

.conf_detail {
  color: #bebebe;
  font-size: 10px;
}
.conf_detail .conf_detail_header {
  font-size: 12px;
  color: white;
  font-weight: bold;
  margin-top: 10px;
}
.conf_detail .payment_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
}
.conf_detail .total_payment_row {
  color: white;
  padding: 5px 0;
  border-top: 2px solid #60647d;
}

.confirm_modal_btn button {
  background-color: #4fc8e9;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 12px;
  width: 100%;
  padding: 5px;
}
.confirm_modal_btn button:hover {
  opacity: 0.9;
}

.totalPrice {
  color: #4fc8e9;
  font-weight: bold;
  font-size: 16px;
  margin-top: -20px;
  margin-bottom: 30px;
}

/* ********************************************************** */

.paymentDetails_wrapper .formDetails_card {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paymentDetails_wrapper .formDetails_card img {
  max-width: 300px;
  margin-top: 3rem;
}

.paymentDetails_reviewCard {
  border: 1px solid;
  border-radius: 10px;
  padding: 1rem;
}
.paymentDetails_reviewCard .paymentDetails_reviewCard_Header img {
  border-radius: 10px;
  margin: 0;
  margin-bottom: 10px;
}
.bookingTimings {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid;
  padding: 5px 0;
  margin: 5px 0;
  gap: 2rem;
}
.bookingDiliveryDetail {
  padding: 5px 0;
  margin: 5px 0;
  border-bottom: 1px solid;
}
.bookingAirport {
  color: #4fc8e9;
  font-size: 16px;
}
.bookingPaymentDetail .conf_detail_header {
  font-size: 20px;
  font-weight: bold;
}
.bookingPaymentDetail .payment_row {
  font-size: 17px;
}
.bookingPaymentDetail .total_payment_row {
  font-size: 17px;
  background-color: #cbcbcb14;
  border-radius: 10px;
  padding: 0.5rem;
  border: none;
  font-weight: bold;
}
/* ******************************************************* */

@media (max-width: 426px) {
  .paymentDetails_wrapper {
    flex-direction: column-reverse;
    padding: 0;
  }
  .paymentDetails {
    width: 100%;
  }
  .paymentDetails_wrapper .paymentDetails_card {
    width: 100%;
  }
  .paymentDetails_wrapper .paymentDetails_card img {
    margin-top: 0;
  }

  .cardRow {
    display: block;
  }
  .cardRow_text {
    width: 100%;
  }
  .cardRow_input {
    width: 100%;
  }
  .cardRow_input input,
  .expiry .expiry_input input {
    height: 36px;
    margin-top: 10px;
  }
}

/* remove Number input button */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.discount_input {
  position: relative;
}
.discount_input input {
  padding-right: 100px;
  color: white;
}
.discount_input .apply_code_btn {
  color: white;
  top: 6px;
  right: 5px;
  position: absolute;
  background: #ff2552;
  padding: 2px 10px;
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;
}

.discount_input .apply_code_btn:hover {
  background-color: transparent;
  border-color: #ff2552;
}

@media (max-width: 426px) {
  .discount_input .apply_code_btn {
    top: 15px;
  }
}

.card-number {
  width: 100% !important;
}


.StripeElement {
  background-color: #252b48;
  /* padding: 1rem 2rem 2rem; */
  /* border-radius: 10px; */
  /* padding: 0.5em 0.6em 0.5em 2.5em; */
  padding: 0.65em 0.6em;
  border: 1px solid #454d80 !important;
  font-size: inherit;
  height: 40px;
  color: #fff !important;
  border-radius: 0.2em;
}

.StripeElement-rtcar-card-num {
  padding-left: 2.75em;
}

.card-num-element {
  position: relative;
}

.card-num-element .stripe-card-svg {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: 0.7em;
}
