.page {
  display: flex;
  gap: 1rem;
}

.hero_content {
  padding: 1rem 0;
  margin-left: 16%;
  width: 86%;
}

.dashboard_widgets {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
}

.widget {
  width: 32%;
  /* width: 24%; */
  background-color: #252b48;
  border-radius: 8px;
  box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 10px;
}
.widget .icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ff2552;
  border-radius: 5px;
  background-color: #1c2038;
}
.money_collected .amount {
  color: #ff2552;
  font-size: 20px;
  font-weight: bold;
}
.car_availability .amount {
  color: #bbffb0;
  font-size: 20px;
  font-weight: bold;
}
.warnings .amount {
  color: #4fc8e9;
  font-size: 20px;
  font-weight: bold;
}
.tom_car_bookings .amount {
  color: #ff6a00;
  font-size: 20px;
  font-weight: bold;
}
.car_availability .icon {
  border-color: #bbffb0;
}
.warnings .icon {
  border-color: #4fc8e9;
}
.tom_car_bookings .icon {
  border-color: #ff6a00;
}

.hero_tag {
  margin-top: 1rem;
  background-color: #252b48;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  font-size: 1.5em;
}

.hero_tag img {
  width: 300px;
}

.primary_button {
  background-color: transparent;
  border: 1px solid #4fc8e9;
  color: #4fc8e9;
  padding: 10px;
  border-radius: 5px;
  font-size: 1rem;
}

.primary_button svg {
  margin-left: 10px;
}

.admin_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
  font-size: 22px;
}

.chart_row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.chart_row .chart {
  width: 50%;
  background-color: #252b48;
  border-radius: 10px;
  padding: 1rem 1rem 0 0.5rem;
}

.chart_wrapper {
  width: 100%;
  height: 350px !important;
  font-size: 12px;
}
.chart_text {
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.chart_row .counters {
  width: 50%;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.chart_row .counters .counter {
  background-color: #252b48;
  width: 48%;
  height: 125px;
  border-radius: 10px;
  padding: 1rem;
}
.chart_row .counters .counter .head {
  text-align: left;
}
.chart_row .counters .counter .car,
.chart_row .counters .counter .rent {
  display: flex;
  justify-content: center;
  align-items: center;
}
.chart_row .counters .counter svg {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  padding: 5px;
  border: 1px solid #4fc8e9;
}
.chart_row .counters .counter .car svg g rect {
  fill: #4fc8e9;
}
.chart_row .counters .counter .rent svg {
  border-color: #ffb600;
}
.chart_row .counters .counter .rent svg g rect {
  fill: #ffb600;
}
.chart_row .counters .counter .stock svg {
  border-color: #f52d56;
}
.chart_row .counters .counter .stock svg g rect {
  fill: #f52d56;
}
.chart_row .counters .counter .onRent svg {
  border-color: #ff6900;
}
.chart_row .counters .counter .onRent svg g rect {
  fill: #ff6900;
}
.chart_row .counters .counter .car_total svg {
  border-color: #ff9c64;
}
.chart_row .counters .counter .car_total svg g rect {
  fill: #ff9c64;
}

.chart_row .counters .counter .count {
  text-align: right;
}

.chart {
  min-height: 100%;
}

.dashboard_row {
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
.dashboard_row .dashboard_container {
  width: 100%;
}
.dashboard_row .section {
  background: #252b48;
  margin-top: 1rem;
  border-radius: 12px;
  width: 100%;
  padding: 1rem;
  height: 310px;
}
.dashboard_row .pending_requests {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dashboard_row .section .title {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: flex-start;
}
.dashboard_row .section .icon {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard_row .section .amount {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: flex-end;
  font-size: 22px;
}

.trips_table table tr div {
  background-color: #1c2038;
  /* border-radius: 10px; */
}
.trips_table table thead th {
  padding: 0 10px;
}
.trips_table table tr td {
  padding: 0;
}
.trips_table table tr td div {
  padding: 10px;
  height: 80px;
  display: flex;
  align-items: center;
}
.trips_table table tr td:first-child div {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.trips_table table tr td:last-child div {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.trips_table table .destination {
  width: 250px;
}
.trips_table table .destination img {
  max-width: 40px;
  border-radius: 100%;
  margin-right: 10px;
}

/* .dashboard_container .trips_table {
  height: 310px;
  overflow-y: scroll;
} */

@media (max-width: 426px) {
  .hero_content {
    margin-left: 0;
    width: 100%;
    padding: 0 0 1rem;
  }
  .hero_tag {
    flex-direction: column;
    justify-content: end;
    align-items: end;
  }
  .hero_tag_text {
    width: 100%;
  }
  .hero_tag img {
    width: 200px;
  }
  .chart_row {
    flex-direction: column-reverse;
  }
  .chart_row .chart,
  .chart_row .counters,
  .chart_row .counters .counter {
    width: 100%;
  }

  .chart_wrapper {
    height: 250px !important;
    font-size: 10px;
  }

  .dashboard_widgets {
    flex-wrap: wrap;
  }
  .dashboard_widgets .widget {
    width: 48%;
    margin: 10px 0;
  }

  .dashboard_row {
    flex-direction: column;
  }
}

.chart_row .counters .counter .list {
  height: 150px;
  overflow-y: auto;
}

.chart_row .counters .counter .list li {
  list-style: none;
}

.status .counters {
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
}

.status .counters .counter {
  background-color: #252b48;
  width: 32%;
  height: 300px;
  border-radius: 10px;
  padding: 1rem;
}
