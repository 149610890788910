.pagewrapper {
  padding: 1rem 0;
}

.booking {
  background: #252b48;
  border-radius: 10px;
  padding: 5px 10px;
}
.booking_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}
.booking_header h4 {
  font-size: 16px;
}
.booking_header button {
  /* background-color: #ff2552; */
  width: 100px;
  padding: 5px;
  color: white;
  border-radius: 3px;
  border: 1px solid transparent;
  font-size: 12px;
  transition: all 0.2s ease-in-out;
  background-color: #4fc8e9;
}

.allUsers_header_actions {
  display: flex;
  gap: 1rem;
}

.allUsers_header_actions button:first-child {
  background-color: #4fc8e9;
}

.booking_header button:first-child:hover {
  background-color: transparent;
  border: 1px solid #4fc8e9;
}

.allUsers_table {
  background-color: #252b48;
  border-radius: 10px;
  padding: 5px 1rem 1rem;
}

table {
  width: 100%;
  border-collapse: separate !important;
  border-spacing: 0px 15px;
}
table thead {
  margin-bottom: 1rem;
}
table .profile img {
  border-radius: 100%;
  max-width: 48px;
}

table .table_actions {
  width: 160px;
}

table .table_actions button {
  width: 70px;
  padding: 5px;
  color: white;
  border-radius: 3px;
  /* border: 1px solid transparent; */
  font-size: 12px;
  transition: all 0.2s ease-in-out;
}

table .table_actions button:first-child {
  background-color: transparent;
  border: 1px solid #4fc8e9;
  margin-right: 1rem;
}

table .table_actions button:last-child {
  background-color: transparent;
  border: 1px solid #ff2552;
}

table .table_actions button:first-child:hover {
  background-color: #4fc8e9;
}

table .table_actions button:last-child:hover {
  background-color: #ff2552;
}

table .table_actions button svg {
  width: 20px;
}

table tr {
  color: #bebebe;
}

table tr .role {
  color: #4fc8e9;
}

.create_coupon_modal {
  width: 500px;
}

.deActive {
  color: #ff2552 !important;
}

.edit_coupon .couponAmount_row {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1rem;
}

@media (max-width: 426px) {
  table {
    font-size: 9px;
    border-spacing: 0px 10px;
  }
  table .profile img {
    max-width: 28px;
  }
  table .table_actions {
    width: 55px;
    text-align: end;
  }
  table .table_actions button {
    width: 50px;
    padding: 2px;
    font-size: 8px;
    margin-bottom: 0.3rem;
    margin-right: 0 !important;
  }
  table .table_actions button svg {
    width: 10px;
  }
  .edit_coupon .couponAmount_row {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
}
