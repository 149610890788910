.selectPricePerDayCalenderWrapper {
  /* width: 100%; */
}
.selectPricePerDayCalenderWrapper
  .dailyOverViewCalender
  .calender_blocks:first-child {
  border-left: none !important;
}
.selectPricePerDayCalenderWrapper .dailyOverViewCalender .calender_blocks {
  border: none;
  border-left: 1px solid #8d94be !important;
  color: #8d94be;
  overflow: hidden;
}

.selectPricePerDayCalenderWrapper
  .dailyOverViewCalender
  .dayHeaderClassNames:first-child {
  border-left: none !important;
}

.selectPricePerDayCalenderWrapper .dailyOverViewCalender .dayHeaderClassNames {
  padding: 8px;
  font-size: 14px;
}
.selectPricePerDayCalenderWrapper .dailyOverViewCalender {
  width: 100%;
}
.selectPricePerDayContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
}

.selectPricePerDayContainer .selectPricePerDayCalenderWrapper {
  width: 55%;
}

.selectPricePerDayContainer .selectPricePerDay .info_wrapper {
  margin: 1rem 0;
}
.selectPricePerDayContainer .selectPricePerDay {
  width: 45%;
  padding: 2rem 1rem;
  background: #252b48;
  border-radius: 10px;
}
.selectPricePerDayContainer .selectPricePerDay .selectPricePerDay_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 1rem;
}
.selectPricePerDayContainer
  .selectPricePerDay
  .selectPricePerDay_header
  .title {
  font-size: 18px;
  font-weight: bold;
}
.selectPricePerDayContainer .selectPricePerDay .selectPricePerDay_header a {
  width: max-content;
}
.selectPricePerDayContainer .selectPricePerDay .selectPricePerDay_descp {
  color: #c0c0c0;
  font-size: 13px;
}
.selectPricePerDayContainer .selectPricePerDay .editPriceBtn {
  background-color: #4fc8e9;
  color: white;
  width: 100%;
  border-radius: 5px;
}

.selectPricePerDayContainer .selectPricePerDay .info_wrapper .price_recomm {
  color: #4fc8e9;
  margin-top: 5px;
}
.price_per_day_calender_event {
  width: 100%;
  /* height: 30px; */
}

.selectPricePerDayCalenderWrapper
  .dailyOverViewCalender
  .calender_blocks
  .fc-daygrid-day-top {
  justify-content: center;
}
.selectPricePerDayCalenderWrapper
  .dailyOverViewCalender
  .calender_blocks
  .fc-daygrid-day-top {
  position: relative;
  z-index: 10;
}
.selectPricePerDayCalenderWrapper
  .dailyOverViewCalender
  .calender_blocks
  .fc-daygrid-dot-event:focus::after {
  background-color: transparent;
}
/* .selectPricePerDayCalenderWrapper
  .dailyOverViewCalender
  .calender_blocks
  .fc-daygrid-day-events {
  z-index: -1;
} */
.price_per_day_event_block {
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  height: 85px;
  align-items: center;
  /* background: #2e203a; */
  background: #1c2038;
  color: #aeafb2;
  left: -2px;
  /* color: #ff2552; */
  /* color: #f0ce4ad4; */
}
.default_price .price_per_day_event_block {
  background: #1c2038;
  color: #aeafb2;
  font-size: 12px;
}
.info_row .fieldWithPrefix input {
  padding-left: 5px;
}
