.mainLoader {
  margin: -1rem;
  position: fixed;
  padding-top: 45vh;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #000000;
  opacity: 0.5;
  text-align: center;
}
