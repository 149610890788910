.carCard_wrapper {
  background-color: #252b48;
  border-radius: 10px;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  transition: all 0.2s ease-in-out;
}
a,
a:hover {
  color: inherit !important;
  text-decoration: none !important;
}
.carCard_wrapper:hover {
  /* transform: scale(1.01); */
  box-shadow: 0px 0px 3px 1px #3c4071;
}
.carCard_hero_img {
  height: 180px;
}
.carCard_hero_img img {
  width: 250px;
  max-height: 100%;
  border-radius: 10px;
  object-fit: contain;
}
.carCard_body {
  display: flex;
  gap: 10px;
}
.carCard_detail {
  padding-top: 1rem;
}
.carCard_title {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: white;
}
.car_details {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 10px 0; */
}
.car_details .car_similar_to {
  font-size: 10px;
  color: #bebebe;
}
.car_details .price_details {
  font-size: 14px;
  color: white;
}
.car_details .price_details svg {
  transform: rotate(90deg);
  margin-left: 10px;
  width: 6px;
}

.car_tags {
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 16px 0;
  gap: 10px;
  font-size: 14px;
  color: white;
  text-transform: capitalize;
}

.car_tags span {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.car_tags span img {
  background-color: #1c2038;
  padding: 5px;
  border-radius: 100%;
  max-width: 25px;
  margin-right: 5px;
}
.card_actions {
  padding: 1rem 0 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.car_costs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* flex-direction: column; */
  font-size: 14px;
  font-weight: bold;
  color: white;
}
.car_costs .total_cost {
  color: #4fc8e9;
}
.car_costs .daily_rate {
  text-align: right;
  display: none;
  /* width: 100%; */
}
.car_status {
  text-align: right;
}
.car_costs .daily_rate_action {
  display: block;
  width: 100%;
}

.car_select_btn {
  margin: 10px 0 0;
  display: flex;
  gap: 10px;
}
.car_select_btn button {
  text-decoration: none;
  display: inline-block;
  text-align: center;
  border: none;
  border-radius: 5px;
  /* background-color: #1c2038; */
  width: 100%;
  padding: 5px;
  color: white;
  font-size: 12px;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  width: 100px;
  border: 1px solid transparent;
}
/* .car_select_btn button:hover {
  background-color: #4fc8e9;
} */

.car_select_btn .primary {
  background-color: #4fc8e9;
}
.car_select_btn .primary:hover {
  border: 1px solid #4fc8e9;
  background-color: transparent;
}

.car_select_btn .secondary {
  background-color: #8d94be;
}

.car_select_btn .secondary:hover {
  border: 1px solid #8d94be;
  background-color: transparent;
}

.MuiModal-root[aria-labelledby='conf_modal'] {
  display: flex;
  justify-content: center;
  align-items: center;
}
.MuiModal-root[aria-labelledby='conf_modal'] .MuiBackdrop-root {
  background: rgba(37, 43, 72, 0.81);
}
.MuiInputLabel-outlined[data-shrink='true'] {
  /* opacity: 0; */
  transform: translate(14px, -9px) scale(0);
}

.confModal {
  width: 345px;
  height: 300px;
  background: #1c2038;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08);
  border-radius: 22px;
  padding: 1rem;
  margin: 1rem;
  text-align: center;
}

.confModal svg {
  width: 80px;
}
.confModal p {
  font-size: 14px;
  color: #9d9fa2;
}

.confModal .btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.confModal .btns button {
  font-size: 12px;
  width: 80px;
  padding: 5px;
  color: white;
  border: none;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  border: 1px solid transparent;
}
.confModal .btns .primary {
  background-color: #ff2552;
}
.confModal .btns .secondary {
  background-color: #8d94be;
}
.confModal .btns .primary:hover {
  border: 1px solid #ff2552;
  background-color: transparent;
}
.confModal .btns .secondary:hover {
  border: 1px solid #8d94be;
  background-color: transparent;
}

.react-confirm-alert-overlay {
  background: rgba(37, 43, 72, 0.81) !important;
  overflow-y: scroll;
  align-items: start;
}

.editModal {
  width: 55vw;
  /* height: 80%; */
  background: #1c2038;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08);
  border-radius: 22px;
  padding: 1.25rem 1rem;
  margin: 1rem;
  position: relative;
}

.editModal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.editModal_close {
  cursor: pointer;
}

.editModal h5 {
  font-weight: bold;
  font-size: 18px;
}

.info_wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.info_input {
  width: 100%;
}

.info_row .MuiInputLabel-root {
  font-family: 'Circular Std', sans-serif !important;
  color: #9d9fa2;
  top: -5px;
  font-size: 13px;
}
.info_row .MuiInputLabel-root.Mui-focused {
  color: #9d9fa2;
  border-color: #454d80;
}
.info_row .MuiOutlinedInput-root {
  color: #9d9fa2;
  height: 40px;
  text-align: left;
  font-size: 14px;
}
.info_row .MuiOutlinedInput-notchedOutline {
  border-color: #454d80;
}
.info_row .MuiOutlinedInput-notchedOutline legend {
  display: none;
}
.info_row .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #454d80;
}
.info_row .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #454d80;
}
.MuiMenu-paper {
  background-color: #252b48 !important;
  color: #fff !important;
  font-size: 14px !important;
}
.airport_name_menu_item {
  font-size: 11px !important;
}

.info_row {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
}
.info_row .field {
  text-align: left;
  width: 100%;
  font-size: 12px;
}

/* .info_row .date_time {
  width: 100%;
}
.info_row .time_date_picker {
  width: 100%;
} */
.info_row .time_date_picker label,
.info_row .time_date_picker .MuiOutlinedInput-root,
.info_row svg {
  color: white;
}

.info_row .expiry_date_picker label,
.info_row .expiry_date_picker .MuiOutlinedInput-root,
.info_row .expiry_date_picker svg {
  color: #adb5bd;
}

.edit_dropdown {
  width: 100%;
  margin: 10px 0 0 0 !important;
}

.user_upload_btn {
  font-family: 'Circular Std', sans-serif !important;
  flex-direction: column !important;
  width: 100% !important;
  padding: 3rem !important;
  background: transparent !important;
  border: 1px solid #454d80 !important;
  gap: 1rem !important;
  color: #9d9fa2 !important;
  font-size: 12px !important;
  font-weight: 450 !important;
}

.confirmation_btn {
  margin-top: 1rem;
}
.confirmation_btn button {
  background-color: #4fc8e9;
  border: none;
  border-radius: 3px;
  padding: 0.5rem;
  width: 100%;
}

.outlined_btn {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid #ffb600;
  border-radius: 5px;
  background-color: transparent;
  width: 100%;
  padding: 5px;
  color: white;
  font-size: 12px;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  width: 100px;
}

.outlined_btn_secondary {
  border-color: #ff2552;
}

.outlined_btn:hover {
  color: white;
  opacity: 0.7;
}

@media (max-width: 426px) {
  .carCard_wrapper,
  .carCard_body {
    width: 100%;
    flex-direction: column;
  }
  .carCard_hero_img img {
    max-width: 100%;
  }
  .carCard_detail {
    padding: 0;
  }
  .card_actions {
    padding: 0.5rem 0;
  }
  .car_select_btn button {
    width: 50%;
    padding: 10px 5px;
  }
  .car_costs .daily_rate {
    display: block;
  }
  .car_costs .daily_rate_action {
    display: none;
  }
  .confModal .btns button {
    width: 50%;
    padding: 10px 5px;
  }
  .react-confirm-alert-overlay {
    overflow-y: scroll;
  }
  .editModal {
    width: 90vw;
    overflow: scroll;
    margin-top: 20rem;
  }
  .info_row {
    gap: 1rem;
    flex-direction: column;
  }
}

.car_card_feature {
  font-size: 14px;
}

.vehicle_status svg {
  fill: #fff;
}

.vehicle_status.MuiFormControl-root {
  padding: 0;
  width: auto;
  margin: 0;
}

.vehicle_status .MuiOutlinedInput-root {
  background: transparent;
  border: 1px solid;
  width: 100px !important;
  height: 30px;
  outline: none;
}

.vehicle_status .MuiInputBase-root {
  font-size: 12px;
  width: 100%;
  color: white;
}

.vehicle_status .MuiOutlinedInput-notchedOutline {
  border: none;
}

.car_select_btn .outlined_btn {
  padding-top: 5px;
  padding-bottom: 5px;
  height: 30px;
}

.carCard_detail .left_bottom_layout {
  display: flex;
  gap: 20px;
}

.car_bookings_link {
  text-decoration: underline !important;
  text-decoration-color: white !important;
}

.car_bookings_link:hover {
  color: rgb(59, 230, 221) !important;
}
