.trip_preferences .info_row {
  align-items: flex-start;
}
.trip_preferences .info_row .field {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.trip_preferences .preferences_section {
  margin: 10px 0;
}

.trip_preferences .preferences_section .title {
  color: #4fc8e9;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 10px;
}
.trip_preferences .preferences_section .title span {
  color: #9d9fa2;
  font-weight: normal;
  font-size: 12px;
}

@media (max-width: 426px) {
  .trip_preferences .preferences_section .info_row {
    flex-direction: column;
  }
}
