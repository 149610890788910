.calender_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.calender_section {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.calender_search {
  position: relative;
  width: min-content;
}

.calender_search input {
  background: transparent;
  border: 1px solid #8d94be;
  border-radius: 8px;
  padding: 10px 25px 10px 10px;
  color: white;
  outline: none;
  font-size: 14px;
  width: 300px;
}

.calender_search svg {
  position: absolute;
  right: 12px;
  top: 10px;
}

.calender_year {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  user-select: none;
}

.calender_year .prev,
.calender_year .next {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 5px;
}

.calender_year svg {
  width: 8px;
}

.calender_year .prev svg {
  transform: rotate(180deg);
}

.calender_section .filter {
  background-color: #252b48;
  padding: 8px 9px 4px 10px;
  border-radius: 8px;
  cursor: pointer;
}

.calender_section .filter svg {
  width: 25px;
}

.calender_type_select svg {
  width: 7px;
  transform: rotate(90deg);
  right: 15px;
  position: absolute;
}

.calender_header .info_row_date .MuiOutlinedInput-notchedOutline,
.calender_header .info_row_date .MuiOutlinedInput-notchedOutline:hover {
  /* border: 1px solid #ffffff !important; */
  /* border: 1px solid !important;
  color: white; */
}

.calender_header .info_row_date .MuiFormControl-root {
  width: 150px;
}
