.go_back {
  cursor: pointer;
  margin: 10px 0;
}

.unavailability_event_block {
  cursor: pointer;
  color: #f0ce4ad4 !important;
}
.unavailability_event_block:hover {
  color: #f0ce4ad4 !important;
}

.unavailabilityModal .reason {
  font-weight: bold;
  font-size: 16px;
}
.unavailabilityModal .reason span {
  font-weight: normal;
  font-size: 14px;
}

.deleteUnavailability button {
  width: 100% !important;
  margin: 10px 0;
}
