.theme_content {
    width: 83%!important;
}

.edit_theme_modal {
    height: 630px!important;
}

.edit_theme_modal .btns {
    margin-top: 20px;
}