@import url('https://fonts.cdnfonts.com/css/circular-std?styles=17909,17911,17907,17905');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');

body {
  margin: 0 !important;
  padding: 1rem 1rem 1rem 1rem !important;
  font-family: 'Circular Std', sans-serif !important;
  font-weight: 450 !important;
  color: white !important;
  background-color: #1c2038 !important;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.flex {
  display: flex;
}
.align-center {
  align-items: center !important;
}
::-webkit-scrollbar {
  width: 20px;
  /* height: 10px; */
  background-color: #1c2038;
  border-radius: 15px;
}
::-webkit-scrollbar-track-piece {
}
::-webkit-scrollbar-thumb {
  background-color: #646981;
  border-radius: 10px;
  border: 6px solid #1c2038;
  border-bottom-width: 3px;
}
