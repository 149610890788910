.create_coupon .info_wrapper {
  margin-top: 2rem;
}
.create_coupon .info_row {
  justify-content: start;
  gap: 1rem;
}
.create_coupon .field {
  width: 50%;
}
.create_coupon .fieldName {
  width: 20%;
  font-size: 14px;
}
.create_coupon .fieldName span {
  color: #9d9fa2;
  font-size: 12px;
}

.create_coupon .couponAmount_row {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1rem;
}

.create_coupon .couponMode {
  width: 30%;
}
.couponDescription {
  background-color: transparent !important;
  outline: none;
  border: 1px solid #454d80;
  border-radius: 5px;
  /* min-height: 40px; */
  color: #9d9fa2;
  font-size: 14px;
  padding: 16.5px 14px;
}
.couponDescription::placeholder {
  color: #9d9fa2 !important;
}
.create_coupon .save_coupon button {
  width: 71.5%;
  color: white !important;
}

@media (max-width: 426px) {
  .info_row {
    flex-direction: row;
  }
  .create_coupon .fieldName {
    width: 30% !important;
    font-size: 12px;
  }

  .create_coupon .fieldName span {
    font-size: 10px;
  }
  .create_coupon .field {
    width: 70%;
  }
  .create_coupon .save_coupon button {
    width: 100%;
  }

  .create_coupon .couponAmount_row {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
  .info_wrapper {
    gap: 1.5rem;
  }
}
