.addManualCar {
  width: 90%;
}

.radio_wrapper {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}
.radio_wrapper .radio {
  width: 100%;
  background: #252b48;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.radio_wrapper .radio_label {
  width: 100%;
}
.radio_wrapper .radio_btn {
  border: none !important;
  font-size: 14px !important;
  padding: 1rem !important;
}
.radio_wrapper .radio_btn svg {
  width: 60px;
}

.info_row_date .date_time .MuiFormControl-root {
  margin: 0;
  width: 50%;
}
.info_row_date .date_time .MuiInputLabel-root {
  font-size: 12px;
  width: 100%;
  color: white;
}
.info_row_date .date_time .MuiInputBase-root {
  font-size: 11px;
  width: 100%;
  /* border: 1px solid white; */
  color: white;
}

.info_row .date_time {
  border: 1px solid #454d80;
  border-radius: 5px;
  margin-top: 10px;
  display: flex;
}

.info_row_date .time_date_picker:first-child {
  border-right: 1px solid #454d80;
}
.info_row_date .time_date_picker {
  width: 50%;
  margin: 6px 0 !important;
  height: 28px;
}
.info_row_date .MuiOutlinedInput-notchedOutline,
.info_row_date .MuiOutlinedInput-notchedOutline:hover {
  /* border: 1px solid #ffffff !important; */
  border: none !important;
  color: white;
}
.info_row_date .time_date_picker label,
.info_row_date .time_date_picker .MuiOutlinedInput-root,
.info_row_date svg {
  color: white;
  font-size: 12px;
}

.time_label {
  top: 2px !important;
}

.timeDropdown svg,
.time_date_picker svg {
  margin-top: -4px;
}

.timeDropdown_paper {
  height: 400px;
}

@media (max-width: 426px) {
  .addManualCar {
    width: 100%;
  }
  .radio_wrapper {
    gap: 1rem;
  }
  .radio_wrapper .radio_btn {
    font-size: 10px !important;
    padding: 1rem 0rem !important;
  }
  .radio_wrapper .radio_btn svg {
    width: 40px;
  }
}

.booking.feature_checkbox_wrapper {
  width: 20%!important;
}
.booking.feature_checkbox_wrapper span:first-child {
  width: 30%;
}