.messages_container {
  background: #252b48;
  box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  display: flex;
}

.messages_sidebar {
  width: 30%;
  border-right: 1px solid #8d94be;
  padding: 1rem 0.5rem 1rem;
}

.messages_sidebar_header .message_sidebar_search {
  position: relative;
  margin: 10px 0 20px;
}

.messages_sidebar_header .message_sidebar_search input {
  border: 1px solid #8d94be;
  border-radius: 5px;
  outline: none;
  background-color: #1c2038;
  color: white;
  font-size: 14px;
  width: 100%;
  padding: 8px 38px 5px 10px;
}

.messages_sidebar_header .message_sidebar_search svg {
  position: absolute;
  right: 10px;
  top: 4px;
  width: 20px;
}

.messages_container .clients_row {
  display: flex;
  gap: 1rem;
  margin: 1rem 0;
  overflow-y: hidden;
  overflow-x: scroll;
  height: 120px;
  font-size: 12px;
  scrollbar-width: none;
  border-bottom: 1px solid #8d94be;
}

.messages_container .clients_row::-webkit-scrollbar {
  width: 15px;
  height: 10px;
  scrollbar-width: thin !important;
}

.messages_container .clients_row::-webkit-scrollbar-track-piece {
  background-color: #1c2038;
  border-radius: 5px;
}

.messages_container .clients_row::-webkit-scrollbar-thumb {
  height: 10px;
  background-color: #646981;
  border-radius: 5px;
  border: 3px solid #1c2038;
  border-bottom-width: 3px;
}

.messages_container .clients_row .add_client {
  width: 60px;
  cursor: pointer;
}

.messages_container .clients_row .add_client_icon {
  width: 60px;
  height: 60px;
  border: 1px solid #4fc8e9;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.messages_container .clients_row .client {
  width: 60px;
  height: 60px;
  cursor: pointer;
  text-align: center;
}

.messages_container .clients_row .client img {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  margin-bottom: 5px;
}

.messages_container .messages_sidebar_body .sidebar_body_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.messages_container .messages_sidebar_body .sidebar_body_header svg {
  cursor: pointer;
}

.messages_container .messages_sidebar_body .client_messages {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: calc(100vh - 450px);
}

.messages_container .messages_sidebar_body .client_message {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
  width: 100%;
  cursor: pointer;
  padding: 5px;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
}

.messages_container .messages_sidebar_body .client_message:hover {
  background-color: #1c2038;
}

.messages_container .messages_sidebar_body .profile_selected {
  background-color: #1c2038;
}

.messages_container .messages_sidebar_body .message_body_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.messages_container .messages_sidebar_body {
  overflow: auto;
}

.messages_container .messages_sidebar_body .client_message .profile img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}

.messages_container
  .messages_sidebar_body
  .client_message
  .message_body
  .title {
  font-size: 14px;
}
.messages_container
  .messages_sidebar_body
  .client_message
  .message_body
  .last_message {
  font-size: 12px;
}

.messages_container .messages_sidebar_body .client_message .message_action {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.messages_main {
  width: 69%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.messages_main .messages_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  border-bottom: 1px solid #8d94be;
  padding: 10px;
}

.messages_main .messages_header .messages_header_profile {
  display: flex;
  align-items: center;
  gap: 10px;
}

.messages_main .messages_header .messages_header_profile .profile img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.messages_main .messages_header .client_status {
  font-size: 12px;
  color: #8d94be;
  display: flex;
  align-items: center;
  gap: 3px;
}

.messages_main .messages_header .client_status .status_dot {
  width: 5px;
  height: 5px;
  border-radius: 100%;
  background-color: green;
}

.messages_main .messages_header .profile_actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.messages_main .messages_header .profile_actions .action {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #1c2038;
  border-radius: 100%;
  cursor: pointer;
}

.messages_main .messages_main_footer {
  padding: 1rem;
  position: relative;
}

.messages_main .messages_main_footer textarea {
  width: 100%;
  border: 1px solid #8d94be;
  border-radius: 7px;
  padding: 10px 50px 10px 15px;
  color: #8d94be;
  outline: none;
  background-color: #1c2038;
}
.messages_main .messages_main_footer .messages_text_actions {
  display: flex;
  align-items: center;
  position: absolute;
  gap: 10px;
  right: 30px;
  top: 25px;
}

.messages_main .messages_main_footer .messages_text_actions .send {
  background-color: #4fc8e9;
  border-radius: 5px;
  padding: 6px 10px;
  font-size: 12px;
  border: none;
  color: white;
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}

.messages_main .messages_main_footer .messages_text_actions .text_action {
  cursor: pointer;
}

.messages_main .messages_main_body {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.messages_main .messages {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 270px);
  overflow-y: scroll;
}

.messages_main .message {
  display: flex;
  align-items: center;
  font-size: 12px;
}
.messages_main .message .message_container {
  width: 40%;
}

.messages_main .message .message_container .message_body {
  padding: 10px;
  border-radius: 15px;
}

.messages_main .message .message_container .message_meta_data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

.messages_main .message .message_container .message_meta_data img {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

.message_send {
  justify-content: end;
}
.message_send .message_body {
  background-color: #4fc8e9;
  border-bottom-right-radius: 0 !important;
}
.message_receive {
  justify-content: start;
}
.message_receive .message_body {
  background-color: #8d94be;
  border-bottom-left-radius: 0 !important;
}
.message_receive .message_meta_data {
  flex-direction: row-reverse;
}

.message_add_client_modal {
  width: 700px;
}

.message_add_client_modal .client_email {
  position: relative;
  margin-bottom: 10px;
}

.message_add_client_modal .client_email input {
  width: 100%;
  background-color: #1c2038;
  border: 1px solid #454d80;
  border-radius: 8px;
  padding: 10px 50px 10px 10px;
  color: white;
  font-size: 14px;
  outline: none;
}

.message_add_client_modal .client_email svg {
  position: absolute;
  right: 15px;
  top: 15px;
}

.message_modal_form div {
  padding: 10px;
}

.word_wrap {
  word-wrap: break-word;
}