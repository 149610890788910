.customzie_modal {
  height: auto;
  width: auto;
}

.customzie_modal .btns {
  margin-top: 10px;
}

.customzie_modal .textfield {
  color: rgb(157, 159, 162);
  font-size: 14px;
  background: transparent;
  border: 1px solid rgb(69, 77, 128);
  border-radius: 5px;
  padding: 10px;
  outline: none;
  min-height: 50px;
}
