.cars_page_pagination {
  margin: 2rem 0 0;
}
.cars_page_pagination .MuiPagination-ul {
  justify-content: center;
}
.cars_page_pagination .MuiPagination-ul li:first-child button,
.cars_page_pagination .MuiPagination-ul li:last-child button {
  border: 1px solid #4fc8e9;
  /* border-radius: 100%; */
}
.cars_page_pagination .MuiPagination-ul li:first-child button svg,
.cars_page_pagination .MuiPagination-ul li:last-child button svg {
  fill: #4fc8e9;
}

.cars_page_pagination .MuiPagination-ul li button {
  color: white;
}
.cars_page_pagination .MuiPagination-ul li button[aria-current='true'] {
  background: transparent;
  color: #4fc8e9;
}

.confirmation_btn .delete_button {
  background-color: #ff2552;
  border: none;
  border-radius: 3px;
  padding: 0.5rem;
  width: 100%;
  margin-top: 10px;
}

@media (max-width: 426px) {
  .cars_page_pagination .MuiPagination-ul {
    flex-wrap: nowrap;
  }
}

.car_confirm_remote {
  height: 430px!important;
}

.delete-error-modal {
  height: 250px!important;
}

.car_bookings_link:hover {
  color: white!important;
}

.Mui-focused .featuredPage_sortOptions .MuiFormControl-root {
  margin: 0;
  margin-top: 10px;
}
.featuredPage_sortOptions .MuiInputLabel-root,
.featuredPage_sortOptions .MuiInputLabel-root .Mui-focused {
  font-size: 12px;
  color: var(--text-white) !important;
}

.featuredPage_sortOptions .MuiInputBase-root {
  font-size: 11px;
  /* width: 280px; */
  border: 1px solid white;
  color: var(--text-white);
}
.featuredPage_sortOptions .MuiOutlinedInput-notchedOutline,
.featuredPage_sortOptions .MuiOutlinedInput-notchedOutline:hover {
  /* border: 1px solid #ffffff !important; */
  border: none !important;
  color: var(--text-white);
}
.featuredPage_sortOptions svg {
  color: var(--text-white);
  font-size: 14px;
}

.featuredPage_sortOptions {
  margin: 1rem 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.sortOptions_no_of_passenger .MuiFormControl-root {
  width: 180px;
}

.featuredPage_sortOptions .attribute_wrapper {
  display: flex;
  justify-content: start;
  gap: 1rem;
}
.featuredPage_sortOptions .sortOptions_arrange {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1rem;
  font-weight: bold;
  font-size: 14px;
}
.sortOptions_arrange {
  float: right;
}
