.info_row_date_sys_conf .date_time .MuiFormControl-root {
  margin: 0;
  width: 100%;
}

.addManualCar {
  margin-bottom: 70px;
}

.info_wrapper .preferences_section .label {
  width: 220px;
  padding-top: 15px;
}

.baseTax_InputAdornment:first-child {
  color: #4fc8e9;
}

.baseTax_InputAdornment:last-child {
  color: red;
}

.info_row .field .withSuffix input {
  padding-left: 5px;
}
.info_row .field .discountFields input {
  width: 20px;
}

.info_row .field.empty input {
  width: 300px!important;
}

.toogle_light_color {
  color: #4fc8e9
}
.info_row .field .discountFieldsMaxDiscount input {
  width: 47px;
}

.create_airport_modal {
  height: 600px!important;
}