.fc-custom_day-view {
  border: 1px solid #8d94be;
  border-radius: 10px;
  user-select: none;
}

.custom_day_view_header {
  background: #252b48;
  border-radius: 10px 10px 0px 0px;
  text-align: center;
  padding: 8px;
}
.custom_day_view table {
  color: #8d94be;
}

.custom_day_view table th {
  border-top: none !important;
  border: 1px solid #8d94be;
  border-bottom: 1px solid #8d94be !important;
  text-align: center;
}
.custom_day_view table th:first-child {
  border-left: none;
}

.custom_day_view table .serial_no {
  width: 80px;
}

.custom_day_view table thead {
  color: #4fc8e9;
  text-transform: uppercase;
}

.custom_day_view table thead th {
  padding: 1rem;
}

.custom_day_view table tbody tr:first-child td {
  border-top: none;
}

.custom_day_view table tbody tr td:first-child {
  border-left: none;
}

.custom_day_view table tbody tr td {
  padding: 1rem;
}

.no_car_available {
  width: 100%;
  text-align: center;
}

.custom_day_view .day_event_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding-left: 2rem;
  position: relative;
}
.custom_day_view .day_event_wrapper .car_name {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: white;
}
.custom_day_view .day_event_wrapper .car_total_cost {
  color: #4fc8e9;
}
.custom_day_view .going_out .day_event_wrapper .car_total_cost {
  color: #ff2552;
}
.custom_day_view .day_event_time {
  text-align: center;
}
.custom_day_view .event_turn {
  background-color: #243953;
}
.custom_day_view .event_turn_goingOut {
  background-color: #37213b;
}

.overviewBooknigModal {
  width: 500px !important;
}
.booking_modal_trip_btn {
  width: 100% !important;
  text-align: center;
  border-radius: 3px;
  border: 1px solid transparent;
  font-size: 12px;
  transition: all 0.2s ease-in-out;
  text-transform: none;
  background-color: #4fc8e9;
  padding: 10px;
}
.booking_modal_trip_btn:hover {
  background-color: transparent;
  border: 1px solid #4fc8e9;
}

/* .custom_day_view .car_detail {
  position: relative;
} */

.custom_day_view_time_wrapper {
  width: 100%;
  height: 100%;
  position: absolute !important;
  padding: 0 !important;
  top: 0;
  left: 0;
  text-align: center;
}
.custom_day_view_time_wrapper div {
  width: 100%;
}

.custom_day_view .car_detail .unavailibility {
  position: absolute;
  top: -5px;
  right: 10px;
}

.custom_day_view .car_detail .unavailibility svg {
  fill: #f0f04b;
}
.custom_day_view .car_detail .containNote svg {
  stroke: #f0f04b;
  fill: none;
}
