.trip_management_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
}

.car_images {
  width: 270px;
}

.car_images .images {
  display: flex;
  align-items: center;
  gap: 10px;
}
.car_images img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.menu {
  width: 30px;
}

.trips_table {
  font-size: 14px;
}

.trips_table .destination_trip {
  width: 150px;
}
.trips_table .destination_trip img {
  margin-right: 10px;
}

.trips_table tbody tr {
  /* cursor: pointer; */
  transition: all 0.2s ease-in-out;
}

.trips_table tbody tr:hover {
  transform: scale(1.02);
}

.notificationHistoryModal {
  width: 430px;
  transform: translate(0, 20%);
}

.trip_modal_carousal {
  text-align: center;
}

.trip_modal_carousal img {
  max-width: 100%;
  border-radius: 20px;
  height: 230px;
}
.notificationHistoryModal .tripDetail {
  font-size: 14px;
  color: white;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}
.notificationHistoryModal .tripDetail_col {
  font-size: 14px;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 50%;
  margin: 0 3px;
}

.notificationHistoryModal .tripDetail_col .req_reimbursement {
  color: #ff2552;
  font-weight: bold;
  cursor: pointer;
}

.notificationHistoryModal .tripDetail span {
  color: #8d94be;
}
.notificationHistoryModal .trip_detail_actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}
.notificationHistoryModal .trip_detail_actions .all_users_primary_button,
.notificationHistoryModal .trip_detail_actions .all_users_secondary_button {
  width: 50% !important;
  padding: 10px;
}

.reimbursementModal {
  width: 500px;
}

.reimbursementModal .confirmation_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.reimbursementModal .confirmation_btn button {
  color: white;
  border-radius: 10px;
}
.reimbursementModal .confirmation_btn button svg {
  margin-right: 10px;
}
.reimbursementModal .confirmation_btn button:first-child {
  background-color: #252b48;
}

.reimbursementModal .user_upload_btn {
  padding: 10px 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reimbursementModal .user_upload_btn div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.trips_table .photos {
  background-color: #1c2038 !important;
}

.trips_table .photos_button {
  background-color: #8d94be !important;
}

.trips_table .reimbursement_th {
  width: 10%;
}
