.addPerson {
  display: grid;
}

.add_person_wrapper {
  width: 70%;
  background-color: #252b48;
  justify-self: center;
  height: auto;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08);
  border-radius: 22px;
  padding: 1.25rem 1rem;
  margin: 1rem;
}
.profile_upload {
  display: grid;
  width: 100%;
}
.profile_img {
  justify-self: center;
  position: relative;
}
.profile_img img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 100%;
  border: 2px solid #4fc8e9;
  /* object-position: 0; */
}
.profile_upload_btn {
  width: 30px;
  height: 30px;
  min-width: auto !important;
  position: absolute !important;
  bottom: 1rem;
  right: 1rem;
  font-family: 'Circular Std', sans-serif !important;
  flex-direction: column !important;
  border: 1px solid #4fc8e9 !important;
  gap: 1rem !important;
  color: #9d9fa2 !important;
  font-size: 12px !important;
  font-weight: 450 !important;
  border-radius: 100% !important;
  background-color: #252b48 !important;
  /* width: 200px;
  height: 200px; */
}

@media (max-width: 426px) {
  .add_person_wrapper {
    width: 100%;
  }
}
