.editWrapper {
  display: flex;
  justify-content: center;
}

.editContainer {
  background-color: #252b48;
  width: 70%;
  padding: 1rem;
  border-radius: 10px;
}

.editContainer .user_upload_btn {
  padding: 1rem 0 !important;
  height: 214px;
}
.editContainer .user_upload_btn img {
  width: 80%;
  height: 180px;
  max-width: 250px;
}

.add_vehicle .carFeatures {
  padding: 1rem 0;
}
.add_vehicle .feature_checkbox_wrapper {
  width: 19% !important;
}

@media (max-width: 426px) {
  .editContainer {
    width: 100%;
  }
  .editContainer .user_upload_btn {
    padding: 3rem 0 !important;
  }
}

.photo_wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, 48%);
}

.photo_row {
  width: 100%;
}

.delete_btn {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 0;
}
