.dropdown_search {
  padding: 0px 16px 6px;
}

.dropdown_search #dropdown_search {
  width: 100%;
  background-color: transparent;
  border: 1px solid #8d94be;
  border-radius: 5px;
  color: white;
  padding: 5px 8px;
  outline: none;
}

.dropdown_search_menu_item {
  align-items: start !important;
  gap: 10px;
}
.dropdown_search_menu_item .card_img img {
  max-width: 70px;
}
.dropdown_search_menu_item .drpdwn_car_details .car_name {
  font-size: 18px;
  /* margin-top: 5px; */
}
.dropdown_search_menu_item .drpdwn_car_details .price_tag {
  color: #4fc8e9;
  font-size: 12px;
  /* margin-top: 5px; */
}
.dropdown_label {
  margin-top: 3px;
}
.carDropdown {
  height: 60px !important;
}
.carDropdown .MuiSelect-select {
  display: flex !important;
  align-items: start !important;
  gap: 10px;
  padding-top: 13px !important;
}

.MuiSelect-select .card_img img {
  /* display: none; */
  max-width: 70px;
  border-radius: 3px;
  object-fit: contain;
  max-height: 55px;
}
.MuiSelect-select .drpdwn_car_details .car_name {
  color: white;
}
.MuiSelect-select .drpdwn_car_details .price_tag {
  /* display: none; */
  font-size: 12px;
}


.car_dropdown_root {
  width: 91%;
}

@media (max-width: 426px) {
  .car_dropdown_root {
    width: 100%;
  }
}
