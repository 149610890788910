.delete-package-btn {
    background-color: #ff2552!important;
}

.delete-package-btn:hover {
    background-color: transparent!important;
    border: 1px solid #ff2552;
}
  
.create_feature_modal {
    height: 430px;
}