.prev_customer_card {
  background-color: #252b48;
  border-radius: 15px;
  padding: 1rem;
  width: 23%;
  /* width: 290px; */
  height: 308px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
}
.prev_customer_card .customer_img img {
  max-width: 100%;
  border-radius: 100%;
  border: 1px solid white;
}
.prev_customer_card .customer_name {
  font-size: 20px;
  font-weight: bold;
}
.prev_customer_card .customer_descp {
  font-size: 12px;
  font-weight: normal;
  color: #c0c0c0;
}

@media (max-width: 768px) {
  .prev_customer_card {
    width: 48%;
  }
}
@media (max-width: 426px) {
  .prev_customer_card {
    width: 100%;
  }
}
